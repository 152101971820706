<template>
  <v-app id="app">
    <router-view />
    <v-footer style="color: white" color="primary" app>
      <span>
        <a style="color: white" href="https://www.orbisdata.pe/"
          >OrbisData Perú</a
        >
        2022 - v1.0
      </span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "app",
  components: {},
  data: () => ({
    //
  }),

  methods: {},
};
</script>
<style scoped>
@import "@/assets/css/main.css";
</style>