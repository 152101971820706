import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/HomeView.vue';
import Layout from '../layouts/layout.vue';
import verifyAuth from '@/middleware/verifyAuth';
Vue.use(VueRouter);

const routes = [
  {
    path: '/auth/login',
    name: 'authLogin',
    beforeEnter: verifyAuth,
    component: () => import('../views/auth/Login.vue'),
  },
  {
    path: '/',
    component: Layout,
    beforeEnter: verifyAuth,
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: "L'Umanitaria - Sistema de Gestión de Socios y Mausoleos",
        },
        component: Home,
      },
      {
        path: '/socios',
        name: 'socios',
        meta: {
          title: 'Socios',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        component: () => import('../views/socios/Socios.vue'),
      },
      {
        path: '/nichos',
        name: 'nichos',
        meta: {
          title: 'Mausoleos',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/nichos/Nichos.vue'),
      },
      {
        path: '/usuarios/internos',
        name: 'usuariosInternos',
        meta: {
          title: 'Usuarios Internos',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/usuarios/Usuarios.vue'),
      },
      {
        path: '/roles',
        name: 'roles',
        meta: {
          title: 'Roles de Usuarios',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        component: () =>
          import('../views/roles/Roles.vue'),
      },
      {
        path: '/accesos',
        name: 'accesos',
        meta: {
          title: 'Accesos',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        component: () =>
          import('../views/accesos/Accesos.vue'),
      },
      {
        path: '/reportes/cuotas',
        name: 'reportes-cuotas',
        meta: {
          title: 'Reportes de Cuotas Sociales',
        },
        component: () =>
          import('../views/reportes/Cuotas.vue'),
      },
      {
        path: '/reportes/pagos',
        name: 'reportes-pagos',
        meta: {
          title: 'Reportes de Cancelaciones de Servicios',
        },
        component: () =>
          import('../views/reportes/Pagos.vue'),
      },
      { path: '*', redirect: '/home' },
    ],
  },
  { path: '*', redirect: '/auth/login' },
];

const router = new VueRouter({
  routes,
});

export default router;
