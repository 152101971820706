import request from '@/plugins/request';
const state = {
  roles: [],
  loadingRoles: false,
};

const getters = {
  getRoles() {
    return state.roles;
  },
  getLoadingRoles() {
    return state.loadingRoles;
  },
};

const mutations = {
  setRoles(state, payload) {
    state.roles = payload;
  },
  setLoadingRoles(state, payload) {
    state.loadingRoles = payload;
  },
};

const actions = {
  async getRoles({ commit }) {
    commit('setLoadingRoles', true);
    const response = await request.get('/roles');
    const success = response?.data?.success;
    if (success) {
      commit('setRoles', response.data.data);
    }
    commit('setLoadingRoles', false);
  },
  async saveRol({ dispatch }, payload) {
    const response = await request.post('/roles', payload);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El Rol se registró de manera correcta',
      });
      dispatch('getRoles');
    }
  },
  async editRol({ dispatch }, payload) {
    const response = await request.put('/roles', payload, {
      params: {
        keyRol: payload.keyRol,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El Rol se actualizó de manera correcta',
      });
      dispatch('getRoles');
    }
  },
  async saveAccesoRol({ dispatch }, payload) {
    const response = await request.post('/accesos/roles', payload, {
      params: {
        keyRol: payload.keyRol,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'Los accesos se actualizaron de manera correcta',
      });
      dispatch('getRoles');
    }
  },
  async deleteRol({ dispatch }, payload) {
    const response = await request.delete(`/roles?keyRol=${payload.keyRol}`);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Rol eliminado correctamente' });
      dispatch('getRoles');
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
