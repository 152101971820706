import request from '@/plugins/request';
const state = {
  pagosPorDetalle: {
    pagos: [],
    keyDetalle: null,
  },
  loadingPagos: false,
};

const getters = {
  getPagosPorDetalle() {
    return state.pagosPorDetalle;
  },
  getLoadingPagos() {
    return state.loadingPagos;
  },
};

const mutations = {
  setPagosPorDetalle(state, payload) {
    state.pagosPorDetalle = payload;
  },
  setLoadingPagos(state, payload) {
    state.loadingPagos = payload;
  },
};

const actions = {
  async getPagosPorDetalle({ commit, getters }, keyDetalle) {
    commit('setLoadingPagos', true);
    commit('setPagosPorDetalle', { pagos: [], keyDetalle: null });
    if (keyDetalle) {
      const response = await request.get('/pagos/detalles', {
        params: {
          keyDetalle: keyDetalle,
        },
      });
      const success = response?.data?.success;
      if (success) {
        commit('setPagosPorDetalle', {
          pagos: response.data.data,
          keyDetalle: keyDetalle,
        });
      }
    }

    commit('setLoadingPagos', false);
  },

  async getPagosExcel({}) {
    const response = await request.get('/pagos/excel');
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
  },

  async savePago({ dispatch }, payload) {
    const response = await request.post('/pagos', payload);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El pago se registró de manera correcta',
      });
      dispatch('getPagosPorDetalle', payload?.keyDetalle);
    }
  },
  async editPago({ dispatch }, payload) {
    const response = await request.put('/pagos', payload, {
      params: {
        keyPago: payload.keyPago,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El pago se actualizó de manera correcta',
      });

      dispatch('getPagosPorDetalle', payload?.keyDetalle);
    }
  },
  async deletePago({ dispatch }, payload) {
    const response = await request.delete(`/pagos?keyPago=${payload.keyPago}`);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El pago se eliminó de manera correcta',
      });
      dispatch('getPagosPorDetalle', payload?.keyDetalle);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
