import request from '@/plugins/request';
const state = {
  accesos: [],
  loadingAccesos: false,
};

const getters = {
  getAccesos: (state) => state.accesos,
  getLoadingAccesos: (state) => state.loadingAccesos,
};

const mutations = {
  setAccesos: (state, accesos) => {
    state.accesos = accesos;
  },
  setLoadingAccesos: (state, loadingAccesos) =>
    (state.loadingAccesos = loadingAccesos),
};

const actions = {
  async getAccesos({ commit }) {
    commit('setLoadingAccesos', true);
    const response = await request.get('/accesos');
    const success = response?.data?.success;
    if (success) {
      commit('setAccesos', response.data.data);
    }
    commit('setLoadingAccesos', false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
