import request from '@/plugins/request';

const state = {
  formasPago: [],
};

const getters = {
  getFormasPago() {
    return state.formasPago;
  }
};

const mutations = {
  setFormasPago(state, payload) {
    state.formasPago = payload;
  }
};

const actions = {
  async getFormasPago({ commit }) {
    const response = await request.get('/formasPago');
    const success = response?.data?.success;
    if (success) {
      commit('setFormasPago', response.data.data);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
