import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
/* import * as Realm from "realm-web"; */
import request from './plugins/request'
import Maska from 'maska'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

/* new Realm.App({ id: "backend-kxzkp" });
const credentials = Realm.Credentials.apiKey(
  "6mWgp8YOz9pynnK3dLTqszfjY02cofYTwiQKJEcZjBfEf4klaw77hxR7HeeYnArh"
);
const app = Realm.getApp("backend-kxzkp")
app.logIn(credentials); */
//const user = await app.logIn(credentials);

Vue.prototype.$request = request
Vue.config.productionTip = false
Vue.use(Maska)
Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
