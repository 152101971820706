import request from '@/plugins/request';
const state = {
  socios: [],
  condicionesSocios: [],
  loadingSocio: false,
};

const getters = {
  getSocios() {
    return state.socios;
  },
  getLoadingSocio() {
    return state.loadingSocio;
  },
  getCondicionesSocios() {
    return state.condicionesSocios;
  },
};

const mutations = {
  setSocios(state, payload) {
    state.socios = payload;
  },
  setLoadingSocio(state, payload) {
    state.loadingSocio = payload;
  },
  setCondicionesSocios(state, payload) {
    state.condicionesSocios = payload;
  },
};

const actions = {
  async getSocios({ commit }) {
    commit('setLoadingSocio', true);
    const response = await request.get('/socios');
    const success = response?.data?.success;
    if (success) {
      commit('setSocios', response.data.data);
    }
    commit('setLoadingSocio', false);
  },
  async getSociosExcel({}) {
    const response = await request.get('/socios/excel');
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
  },
  async saveSocio({ dispatch }, payload) {
    const response = await request.post('/socios', payload);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El socio se registró de manera correcta',
      });
      dispatch('getSocios');
    }
  },
  async editSocio({ dispatch }, payload) {
    const response = await request.put('/socios', payload, {
      params: {
        keySocio: payload.keySocio,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El socio se actualizó de manera correcta',
      });
      dispatch('getSocios');
    }
  },
  async deleteSocio({ dispatch }, payload) {
    const response = await request.delete(
      `/socios?keySocio=${payload.keySocio}`
    );
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Socio eliminado correctamente' });
      dispatch('getSocios');
    }
  },
  async getCondicionesSocios({commit}) {
    const response = await request.get('/socios/condiciones');
    const success = response?.data?.success;
    if (success) {
      commit('setCondicionesSocios', response.data.data);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
