import request from '@/plugins/request';
const state = {
  nichos: [],
  loadingNichos: false,
};

const getters = {
  getNichos() {
    return state.nichos;
  },
  getLoadingNichos() {
    return state.loadingNichos;
  },
};

const mutations = {
  setNichos(state, payload) {
    state.nichos = payload;
  },
  setLoadingNichos(state, payload) {
    state.loadingNichos = payload;
  },
};

const actions = {
  async getNichos({ commit }) {
    commit('setLoadingNichos', true);
    const response = await request.get('/nichos');
    const success = response?.data?.success;
    if (success) {
      commit('setNichos', response.data.data);
    }
    commit('setLoadingNichos', false);
  },
  async getNichosExcel({}) {
    const response = await request.get('/nichos/excel');
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
  },
  async saveNicho({ dispatch }, payload) {
    const response = await request.post('/nichos', payload);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Nicho registrado correctamente' });
      dispatch('getNichos');
    }
  },
  async editNicho({ dispatch }, payload) {
    const response = await request.put(`/nichos`, payload, {
      params: {
        keyNicho: payload.keyNicho,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Nicho editado correctamente' });
      dispatch('getNichos');
    }
  },
  async deleteNicho({ dispatch }, payload) {
    const response = await request.delete(`/nichos`, {
      params: {
        keyNicho: payload.keyNicho,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Nicho eliminado correctamente' });
      dispatch('getNichos');
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
