import { removeItem, setItem } from '@/utils/localStorage';
import Swal from 'sweetalert2';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async showConfirmAlert({ commit }, payload) {
    return await Swal.fire({
      text: payload.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    });
  },
  async showSuccessAlert({ commit }, payload) {
    return await Swal.fire({
      text: payload.text,
      icon: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    });
  },
  async showErrorAlert({ commit }, payload) {
    return await Swal.fire({
      text: payload.text,
      icon: 'error',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
