import request from '@/plugins/request';
import { removeItem, setItem } from '@/utils/localStorage';

const state = {
  usuario: null,
  accesosUsuario: null,
  token: null,
};

const getters = {
  getUsuario() {
    return state.usuario;
  },
  getAccesosUsuario() {
    console.log(state.accesosUsuario);
    return state.accesosUsuario;
  },
  getToken() {
    return state.token;
  },
  getAccesoNivel1(state) {
    return function (vista) {
      return state.accesosUsuario.find((acceso) => acceso.vista === vista);
    };
  },
  getAccesoNivel2(state) {
    return function (padre, vista) {
      return state.accesosUsuario
        .find((acceso) => acceso.vista === padre)
        .subvistas.find((subvista) => subvista.vista === vista);
    };
  },
  getAccesoNivel3() {
    return function (padre1, padre2, vista) {
      return state.accesosUsuario
        .find((acceso) => acceso.vista === padre1)
        ?.subvistas.find((subvista) => subvista.vista === padre2)
        ?.subvistas.find((subvista) => subvista.vista === vista);
    };
  },
};

const mutations = {
  setUsuario(state, payload) {
    state.usuario = payload;
  },
  setAccesosUsuario(state, payload) {
    console.log('setAccesosUsuario', payload);
    state.accesosUsuario = payload;
  },
  setToken(state, payload) {
    state.token = payload;
    request.defaults.headers = {
      Authorization: `Bearer ${payload}`,
    };
  },
};

const actions = {
  async login({ commit }, payload) {
    const response = await request.post('/auth/login', payload);
    const success = response?.data?.success;
    if (success) {
      setItem('token', response.data.data.accessToken);
      commit('setUsuario', response.data.data.usuario);
      commit('setToken', response.data.data.accessToken);
      commit('setAccesosUsuario', response.data.data.usuario.rol.accesos);
      return true;
    }
  },

  async getUsuario({ commit }) {
    const response = await request.get('/auth/usuario');
    const success = response?.data?.success;
    if (success) {
      commit('setUsuario', response.data.data);
      commit('setAccesosUsuario', response.data.data.rol.accesos);
    }
  },

  logout({ commit }) {
    removeItem('token');
    commit('setUsuario', null);
    commit('setAccesosUsuario', null);
    commit('setToken', null);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
