import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import socio from './socios';
import cuota from './cuota';
import nichos from './nichos';
import detalleNicho from './detalleNicho';
import pagos from './pagos';
import alerts from './alerts';
import usuarios from './usuarios';
import roles from './roles';
import accesos from './accesos';
import reportes from './reportes';
import formasPago from './formasPago';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    socio,
    cuota,
    nichos,
    detalleNicho,
    pagos,
    alerts,
    usuarios,
    roles,
    accesos,
    reportes,
    formasPago
  },
});
