import request from '@/plugins/request';
const state = {
  detalles: [],
  loadingDetalles: false,
};

const getters = {
  getDetalles() {
    return state.detalles;
  },
  getLoadingDetalles() {
    return state.loadingDetalles;
  },
};

const mutations = {
  setDetalles(state, payload) {
    state.detalles = payload;
  },
  setLoadingDetalles(state, payload) {
    state.loadingDetalles = payload;
  },
};

const actions = {
  async getDetallesPorNicho({ commit }, payload) {
    commit('setDetalles', []);
    commit('setLoadingDetalles', true);
    if (payload?.keyNicho) {
      const response = await request.get('/detalles/nichos', {
        params: {
          keyNicho: payload.keyNicho,
        },
      });
      const success = response?.data?.success;
      if (success) {
        commit('setDetalles', response.data.data);
      }
    }
    commit('setLoadingDetalles', false);
  },
  async getDetallesExcel({}) {
    const response = await request.get('/detalles/excel');
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
  },
  async saveDetalle({ dispatch }, payload) {
    const response = await request.post('/detalles', payload);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'Detalle de Nicho registrado correctamente',
      });
      dispatch('getDetallesPorNicho', { keyNicho: payload.keyNicho });
    }
  },
  async editDetalle({ dispatch }, payload) {
    const response = await request.put('/detalles', payload, {
      params: {
        keyDetalle: payload.keyDetalle,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'Detalle de Nicho editado correctamente',
      });
      dispatch('getDetallesPorNicho', { keyNicho: payload.keyNicho });
    }
  },
  async deleteDetalle({ dispatch }, payload) {
    const response = await request.delete('/detalles', {
      params: {
        keyDetalle: payload.keyDetalle,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'Detalle de Nicho eliminado correctamente',
      });
      dispatch('getDetallesPorNicho');
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
