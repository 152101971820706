import request from '@/plugins/request';
const state = {
  usuariosIternos: [],
  loadingUsuariosInternos: false,
};

const getters = {
  getUsuariosInternos() {
    return state.usuariosIternos;
  },
  getLoadingUsuariosInternos() {
    return state.loadingUsuariosInternos;
  },
};

const mutations = {
  setUsuariosInternos(state, payload) {
    state.usuariosIternos = payload;
  },
  setLoadingUsuariosInternos(state, payload) {
    state.loadingUsuariosInternos = payload;
  }
};

const actions = {
  async getUsuariosInternos({ commit }) {
    commit('setLoadingUsuariosInternos', true);
    const response = await request.get('/usuarios/internos');
    const success = response?.data?.success;
    if (success) {
      commit('setUsuariosInternos', response.data.data);
    }
    commit('setLoadingUsuariosInternos', false);
  },
  async saveUsuarioInterno({ dispatch }, payload) {
    const response = await request.post('/usuarios/internos', payload);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El usuario se registró de manera correcta',
      });
      dispatch('getUsuariosInternos');
    }
  },
  async editUsuarioInterno({ dispatch }, payload) {
    const response = await request.put('/usuarios/internos', payload, {
      params: {
        keyUsuario: payload.keyUsuario,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'El usuario se actualizó de manera correcta',
      });
      dispatch('getUsuariosInternos');
    }
  },

  async editPasswordUsuarioInterno({ dispatch }, payload) {
    const response = await request.put('/usuarios/internos/password', payload, {
      params: {
        keyUsuario: payload.keyUsuario,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: 'La contraseña se actualizó de manera correcta',
      });
    }
  },
  async bloquearUsuarioInterno({ dispatch }, payload) {
    const response = await request.put('/usuarios/internos/acceso', payload, {
      params: {
        keyUsuario: payload.keyUsuario,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', {
        text: `El usuario se ${!payload.estaActivo ? 'bloqueó' : 'desbloqueó'} de manera correcta`,
      });
      dispatch('getUsuariosInternos');
    }
  },
  async deleteUsuarioInterno({ dispatch }, payload) {
    const response = await request.delete(
      `/usuarios/internos?keyUsuario=${payload.keyUsuario}`
    );
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Usuario eliminado correctamente' });
      dispatch('getUsuariosInternos');
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
