import { getItem } from '@/utils/localStorage';
import store from '@/store';

export default async function (to, from, next) {
  const token = getItem('token');
  if (to.name === 'authLogin') {
    // se dirige a la ruta de login
    if (token) {
      // tiene token
      store.commit('setToken', token);
      store.dispatch('getUsuario');
      next({ name: 'home' });
      return;
    } else {
      // no tiene token
      next();
    }
  } else {
    if (!token) {
      // se dirige a una vista interno y no tiene token
      next({ name: 'authLogin' });
    } else {
      // se dirige a una vista interno y tiene token
      if (!store.getters.getUsuario) {
        store.commit('setToken', token);
        await store.dispatch('getUsuario');
      }
      next();
    }
  }
}
