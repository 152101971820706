import request from '@/plugins/request';

const state = {
  cuotas: [],
  loadingCuota: false,
};

const getters = {
  getCuotas() {
    return state.cuotas;
  },
  getLoadingCuota() {
    return state.loadingCuota;
  },
};

const mutations = {
  setCuotas(state, payload) {
    state.cuotas = payload;
  },
  setLoadingCuota(state, payload) {
    state.loadingCuota = payload;
  },
};

const actions = {
  async getCuotasPorSocio({ commit }, payload) {
    commit('setCuotas', []);
    commit('setLoadingCuota', true);
    const response = await request.get('/cuotas/socios', {
      params: {
        keySocio: payload.keySocio,
      },
    });
    const success = response?.data?.success;
    if (success) {
      commit('setCuotas', response.data.data);
    }
    commit('setLoadingCuota', false);
  },
  async getCuotasExcel({}) {
    const response = await request.get('/cuotas/excel');
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
  },
  async saveCuota({ dispatch }, payload) {
    const response = await request.post('/cuotas', payload);
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Cuota registrada correctamente' });
      dispatch('getCuotasPorSocio', { keySocio: payload.keySocio });
    }
  },
  async editCuota({ dispatch }, payload) {
    const response = await request.put('/cuotas', payload, {
      params: {
        keyCuota: payload.keyCuota,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Cuota editada correctamente' });
      dispatch('getCuotasPorSocio', { keySocio: payload.keySocio });
    }
  },
  async deleteCuota({ dispatch }, payload) {
    const response = await request.delete('/cuotas', {
      params: {
        keyCuota: payload.keyCuota,
      },
    });
    const success = response?.data?.success;
    if (success) {
      dispatch('showSuccessAlert', { text: 'Cuota eliminada correctamente' });
      dispatch('getCuotasPorSocio', { keySocio: payload.keySocio });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
