import request from '@/plugins/request';
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getReporteCuotas({}, payload) {
    const response = await request.get('/reportes/cuotas', { params: payload });
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
    return false;
  },
  async getReporteCuotasExcel({}, payload) {
    const response = await request.get('/reportes/cuotas/excel', {
      params: payload,
    });
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
    return false;
  },
  async getReportePagos({}, payload) {
    const response = await request.get('/reportes/pagos', { params: payload });
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
    return false;
  },
  async getReportePagosExcel({}, payload) {
    const response = await request.get('/reportes/pagos/excel', {
      params: payload,
    });
    const success = response?.data?.success;
    if (success) {
      return response.data.data;
    }
    return false;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
