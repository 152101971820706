<template>
  <div class="home">
    <img src="@/assets/logo-humanitarias.png" />
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {},
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home img {
  max-width: 400px;
  max-height: 400px;
}

@media (max-width: 576px) {
  .home img {
    max-width: 200px;
    max-height: 300px;
  }
}
</style>
