<template>
  <v-app>
    <v-app-bar color="primary" app>
      <v-app-bar-nav-icon
        color="white"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title color="grey">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary app>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="@/assets/logo-humanitarias.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
          <v-list-item-title class="h1">{{ usuario.correo }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <span v-for="(page, i) in routesWithAccesos" :key="i">
          <v-list-item v-if="!page.children" :to="page.link" link>
            <v-list-item-icon>
              <v-icon v-text="page.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon v-text="page.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(childPage, i) in page.children"
              :key="i"
              :to="childPage.link"
              link
            >
              <v-list-item-icon>
                <v-icon v-text="childPage.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ childPage.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </span>
      </v-list>
      <v-divider></v-divider>
      <template v-slot:append>
        <v-list dense>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-account-cancel</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Salir</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app class="background-gray">
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </v-app>
</template>
  
  <script>
// @ is an alias to /src

export default {
  name: "layout",
  data() {
    return {
      drawer: true,
      routes: [
        { title: "Home", icon: "mdi-home", link: "/home", name: "home" },
        {
          title: "Socios",
          icon: "mdi-account-tie ",
          link: "/socios",
          name: "socios",
        },
        {
          title: "Mausoleos",
          icon: "mdi-church",
          link: "/nichos",
          name: "mausoleos",
        },
        {
          title: "Usuarios",
          icon: "mdi-account",
          link: "/usuarios/internos",
          name: "usuarios",
        },
        {
          title: "Roles",
          icon: "mdi-account-supervisor ",
          link: "/roles",
          name: "roles",
        },
        {
          title: "Accesos",
          icon: "mdi-key ",
          link: "/accesos",
          name: "accesos",
        },
        {
          title: "Reportes",
          icon: "mdi-chart-box",
          name: "reportes",
          children: [
            {
              title: "Cuotas Sociales",
              icon: "mdi-account-group",
              link: "/reportes/cuotas",
              name: "reportes-cuotas",
            },
            {
              title: "Cancelaciones de Servicios",
              icon: "mdi-cog",
              link: "/reportes/pagos",
              name: "reportes-pagos",
            },
          ],
        },
        // { title: "Pagos", icon: "mdi-cash-register" , link : "login"},

        { title: "Acerca de Humanitaria", icon: "mdi-information-variant", link: "" },
      ],
      routesWithAccesos: [],
    };
  },
  mounted() {
    this.filterRoutesPorAccesos();
    this.$store.dispatch("getSocios");
    this.$store.dispatch("getNichos");
    this.$store.dispatch("getUsuariosInternos");
    this.$store.dispatch("getRoles");
    this.$store.dispatch("getAccesos");
    this.$store.dispatch("getCondicionesSocios");
    this.$store.dispatch("getFormasPago");
  },

  computed: {
    title() {
      return this.$route.meta.title;
    },
    accesos() {
      return this.$store.getters.getAccesosUsuario;
    },
    usuario() {
      return this.$store.getters.getUsuario;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "authLogin" });
    },
    filterRoutesPorAccesos() {
      this.routesWithAccesos = this.routes.filter((route) => {
        const tieneAcceso = this.accesos.find((acceso) => {
          return acceso.vista === route.name;
        });
        if (tieneAcceso && route.children) {
          route.children = route.children.filter((child) => {
            const tieneAccesoChildren = tieneAcceso.subvistas.find((acceso) => {
              return acceso.vista === child.name;
            });
            return tieneAccesoChildren;
          });
        }
        return tieneAcceso;
      });
      console.log(this.routesWithAccesos);
    },
  },
};
</script>
  <style scoped>
.theme--light.v-sheet {
  color: white;
}

.v-list-item {
  font-family: BeVietnam-SemiBold !important;
}

.v-list-item:hover {
  color: #311def !important;
  background-color: #eae8fc;
  font-family: BeVietnam-SemiBold;
}

.v-list .v-list-item--active {
  color: #311def !important;
  background-color: #dbdbdb;
  font-family: BeVietnam-SemiBold;
}
</style>
  