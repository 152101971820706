import axios from 'axios';
import store from '../store';
const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 120000,
});

request.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error?.code === 'ERR_NETWORK') {
      store.dispatch('showErrorAlert', {
        text: 'No se pudo conectar con el servidor',
      });
      return;
    }
    const errorMessage =
      error?.response?.data?.message || error?.message || 'Ocurrió un error';
    store.dispatch('showErrorAlert', { text: errorMessage });
  }
);

export default request;
